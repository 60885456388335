.c-pagination 
{
  @include reset_list;
  display:block;
  padding-top:1em;
  padding-bottom:1em;
  ul {
    .c-pagination__item ,
    .page-item
    {
      display:inline-block;
      width:42px;
      a,
      span 
      {
        text-align:center;
        overflow:hidden;
        text-decoration:none;
        width:40px;
        height:40px;
        line-height:40px;
        display:block;
      }
      &.active ,
      a:hover 
      {
        background-color:$branding_blue;
        color:#FFF !important;
        border-radius:50%;
      }
      
      &--btn {
        a {
          background-color:transparent;
          color:#A3A3A3;
          font-weight:bold;
          font-size: 1.1rem;
        }
      }
      &.is-active {
        span {
         background-color:$branding_blue;
         color:#FFF;
         border-radius:50%;
       }
     }
   }
 }
}