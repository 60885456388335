.c-news {
  @extend .container;
  margin-bottom: 6rem;
  .c-news__header {
    text-align: center;
    margin:4em 0 3em;
    h1 {
      font-size: 2.2rem;
    }
  }
  .c-news__grid {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3em;
    @include desktop {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .c-news__article {
    a {
      position:relative;
    }
    header {
      figure {
        img {
          transition:.9s all;
          height:230px;
          object-fit:cover;
          width:100%;
          object-position:0 30%;
          @include mobile-xs {

            height:150px;

          }
        }
        position:relative;
        overflow:hidden;
        transition:.3s all;
        &::before {
          content:("");
          position:absolute;
          display:block;
          right:0;
          top:50%;
          left:calc(100% + 50px);
          transform:translate(-50% , -100%);
          width:100px;
          height:1px;
          background-color:#FFF;
          transition:.3s all;
          opacity: 0;
        }
        &::after {
          opacity: 0;
          content:("");
          position:absolute;
          display:block;
          right:0;
          top:calc(0% - 100px);
          left:50%;
          width:1px;
          height:100px;
          background-color:#FFF;
          transition:.3s all;
        }
      }
    }
    a {
      &:hover {
        header {
          figure {
            opacity: .5;
            img {
              transform:scale(1.04);
            }
            &::before {
              opacity: 1;
              left:calc(50%);
              transform:translate(-50% , -50%);
              z-index: 1;
            }
            &::after {
              opacity: 1;
              top:50%;
              transform:translate(-50% , -50%);
              z-index: 1;
            }
          }
        }
      }
    }
    img {
      display:block;
    }
    margin-bottom: 1.5em;
    @include mobile {
      margin-bottom: 1em;
    }
  }
}