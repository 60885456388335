.c-entradas {

  .c-entradas__container {
    max-width: 700px;
    &--artista {
      max-width: 1024px
    }
  }

  .c-entradas__information {

    h2 , b {
      font-weight:600;
    }
    p {

      // line-height: 1.7em;
    }

  }
  .c-entradas__photo {
    background-color:#FFF;
    border-bottom:4px solid #111;
    border-left:1px solid #EFEFEF;
    border-right:1px solid #EFEFEF;
    position:relative;

    img {
      max-height: 400px;
      object-fit: cover;
    }

    &-figcaption {
      position:relative;
      padding:0 20px;
    }
  }
  .c-entradas__date {
    &-day {
      width:110px;
      transform:translateY(-1em);
      background-color:$branding_blue;
      color:$branding_white;
      font-weight:bold;
      padding:20px;
      text-align:center;
      box-shadow:0px 4px 2px #444;
      p {
        margin:0;
        padding:0;
        display:block;
        line-height: 1em;
        margin-bottom: .25em;
      }
      .item-day {
        font-size: .8rem;
        font-weight:600;
      }
      .item-number {
        font-size: 2rem;
        font-weight:bold;
      }
      .item-month {
        font-size: .8rem;
        font-weight:600;
      }
    }
  }
}

.c-block-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: .5em;
  margin-top:2em;
  margin-bottom:1.4em;
  &__link {
    color: #fff;
    background-color: #32373c;
    border-radius: 9999px;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1.125em;
    padding: calc(.667em + 2px) calc(1.333em + 2px);
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word;
    box-sizing: border-box;
    @extend .dim;
  }
}