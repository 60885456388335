.c-page {
  address {
    svg {
      width: 1em;
      height: auto;
    }
  }

  .c-page__header {
    text-align:center;
    position:relative;
    margin-top: 100px;
    transform:translateY(-50px);
    &::before {
      content:("");
      display:block;
      position:absolute;
      width:1px;
      height:80px;
      background-color:#000;
      top:-100px;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
    }
    p {
      padding:0;
      font-weight: bold;
      text-transform: uppercase;
      margin:0 0 1em 0;
    }
    h1 {
      margin:0;
      padding:0;
      @include font-size(3.5rem);
    }
  }
  .c-page__content {
    h2 , h3 {
      padding-top:30px;
      display:block;
      font-size: 1.7em;
    }
    ul {
      margin:0;
      padding:0;
      margin-bottom:2em;
      li {
        list-style-position:  inside;
        margin-bottom:1em;
        line-height:1.5em;
      }
    }
    &-title {
      font-size:2.3em !important;
      font-weight:600;
      margin-top: 0;
    }
    &-text {
      .has-white-color.has-text-color.has-background {
        border-left:10px solid #333;
        color:#FFF;
        padding:40px 20px;
        background-color:#a32b89 !important;
        border-radius:8px;
        a {
          color:#FFF;
        }
      }
    }
  }
  // .c-page__header {
  //   h1 {
  //     @include font-size(4.5rem);
  //     text-align:center;
  //     padding:0;
  //     margin:0;
  //   }
  // }

}