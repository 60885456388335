#body {

  &.is-active-menu-open {
    background-color:#000;
    #main , #footer , .c-video {
      visibility: hidden;
    }

    .c-menu {
      display:block;
    }

    .header__btn-mobile-closse {
      display:inline-block;
    }
    .header__btn-mobile-open {
      display:none;
    }
  }

}