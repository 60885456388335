.c-searchform {
  input ,
  button
  {
   @include reset;

 } 
 .item-text {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.7;
  text-transform: none;
  margin: 0;
  background: #fff;
  border: 2px solid #CCC;
  outline: none;
  -webkit-transition: border-color linear .3s;
  -moz-transition: border-color linear .3s;
  -o-transition: border-color linear .3s;
  transition: border-color linear .3s;
  box-sizing: border-box;
  -webkit-appearance: none;
  height:50px;
  width:100%;
  padding:0 16px;
  &:focus {
    border-color: #1e73be;
  }
}
.item-submit {
  display:block;
  margin: 10px 0 0;
  line-height: 18px;
  padding: .6em 1.5em .5em !important;
  border-color: #1e73be;
  color:#FFF;
  background-color: #1e73be;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.7;
  text-transform: none;
  cursor:pointer;
  transition:.2s all;
  border:2px solid;
  &:hover {
    background-color: #fff;
    color: #eeba20;
    border-color: #eeba20;
    border:2px solid;
  }
}
}