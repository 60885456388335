.c-menu {
  @include reset_list;
  margin-top: 1.5em;
    display:flex;
    justify-content: space-between;
    flex:1;
  a {
    text-decoration:none;
    font-weight: 500;
    #body.body--page-index & ,
    #body.body--page-noticias & ,
    #body.body--page-contacto & 
    {
      color:#FFF;
    }
  }
  ul {
    li {
      display:inline-block;
      a {
        padding:20px;
        display:block;
      }
    }
  }


  @include mobile-theme  {
    display:none;
    ul {
      width:100%;
      display:flex;
      flex-wrap:wrap;
      flex:1;
      li {
        border-top:1px solid #CCC;
        display:block;
        width:100%;
      }
    }


  }
}