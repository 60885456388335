.footer {
	min-height:300px;
  background-color:#000;
  color:#EFEFEF;
  overflow:hidden;
  margin-top: 8em;
  width:100%;
  display:block;
  a {
    color:#EFEFEF;
  }
  .footer__container {
    max-width: 1800px;
    margin:0 auto;
    padding:60px 30px;
  }
  .footer__links {
    @include reset_list;
    ul {
      li {
        display:inline-block;
        a {
          padding:10px;
          display:block;
          @extend .dim;
        }
      }
    }
      text-align: center;
      font-size: .9em;
      padding-bottom:30px;
    a {
      text-decoration:none;
    }
  }
  
  .footer__social {
    font-size: 1.4em;
    &-item {
      margin:4px;
      a {
        transition:.3s all;
        border-radius:50%;
        width:36px;
        height:36px;
        display:block;
        text-align: center;
        line-height: 38px;
          border-radius:50%;
        &:hover {
          background-color:#FFF;
          color:#000;
          transform:scale(1.1);
        }
      }
    }
  }
}

