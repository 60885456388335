.header {
  position:relative;
  min-height: 100%;
  .header__inner {
    position: fixed;
    z-index: 1000;
    top:0px;
    width:100%;
    box-shadow:3px 3px 3px #CCC;
    background-color:rgba(#FFF,.95);
    #body.body--page-index & ,
    #body.body--page-noticias & ,
    #body.body--page-contacto & 

    {
      box-shadow:initial;
      background-color:rgba(#000,.5);
      @include mobile {
        background-color:rgba(#000,.8);
      }
    }
  }
  .header__logo {
    margin-top: 1em;
    margin-right:1em;
    @extend .dim;
    img {
      width:300px;
      margin-top: 13px;
      @include mobile-theme  {
        margin-top:0;
        max-width:75%;
      }
    }
  }
  .header__btn-mobile {
    display: none;
    @include mobile-theme  {
      display:block;
    }
    &-closse {
      display:none;
    }
    .item-btn {
      margin:auto;
      text-decoration: none;
      text-align: right;
      @include reset;
      position:fixed;
      top:12px;
      border:0;
      right:10px;
      cursor: pointer;
      background-color:$branding_red_2;
      color:#FFF;
      border-radius: 2px;
      // box-shadow:0px 4px 2px #000;
      padding:4px 20px;
      font-size: .9em;
      &:hover {
      }
    }
  }
}