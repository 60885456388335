



.e-slide-in-bottom-home {
  animation: e-slide-in-bottom-home 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes e-slide-in-bottom-home {
  0% {
    transform: translateY(100px) scale(1.1);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}



.e-heartbeat {
  animation: e-heartbeat 1.5s ease-in-out both;
}
@keyframes e-heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}


.e-tracking-in-contract  {
  animation: e-tracking-in-contract 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@keyframes e-tracking-in-contract {
  0% {
    letter-spacing: .05em;
    opacity: 0;
    filter: blur(12px);
  }
  
  70% {
    filter: blur(1px);
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.e-c-acces-sections-animated {
	animation: e-c-acces-sections-animated .4s cubic-bezier(0.790, 0.575, 0.565, 1.000) both;
}
@keyframes e-c-acces-sections-animated {
	0% {
		opacity: 0;
		display:none;
		background-size:57% 57%;
	}
	100% {
		opacity: 1;
		display:block;
		background-size:40% 40%;
		background-repeat:no-repeat;
		background-position:center 40%;

	}
}

.e-slide-in-bottom {
	animation: e-slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes e-slide-in-bottom {
	0% {
		transform: translateY(-300px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}


.e-fade-in-left {
	animation: e-fade-in-left .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation-delay: 0s;

}
@keyframes e-fade-in-left {
	0% {
		transform: translateX(-10vw) scale(1);
		opacity: 0;

	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}




.e-fade-out-left {
	animation: e-fade-out-left .2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation-delay: 0s;

}
@keyframes e-fade-out-left {
	0% {
		transform: translateX(0vw) scale(1);
		opacity: 1;

	}
	100% {
		transform: translateX(-10vw);
		opacity: 0;
	}
}

.e-fade-out {
  animation: e-fade-out .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: .4s;

}
@keyframes e-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}




.e-animation-text-focus-in-100 {
	animation: e-animation-text-focus-in-100 .5s cubic-bezier(0.150, 0.085, 0.680, 0.530) both;
}
@keyframes e-animation-text-focus-in-100 {
	0% {
		filter: blur(12px);
		opacity: 0;

	}
	100% {
		filter: blur(0px);
		opacity: 1;


	}
}


.e-fade-in {
	animation: e-fade-in 1.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes e-fade-in {
	0% {
		opacity: 0;
		display:none;

	}
	100% {
		opacity: 1;
		display:block;

	}
}



.e-animation-focus-in-scale {
  animation: e-animation-focus-in-scale .2s cubic-bezier(0.150, 0.085, 0.680, 0.530) both;
}
@keyframes e-animation-focus-in-scale {
  0% {

    filter: blur(80px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;


  }
}

.e-flip-in-hor-bottom {
  animation: e-flip-in-hor-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes e-flip-in-hor-bottom {
  0% {
    transform: scale(1.05) rotateX(10deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}