// E-MIN E-MAX
.e-max-height-px-100 {
  max-height: 100px;
}
.e-min-height-px-270 {
  min-height: 270px;
}
.e-max-height-px-500 {
  max-height: 500px;
}
.e-max-height-px-500 {
  max-height: 500px;
}
.e-max-height-vh-50 {
  max-height: 50vh;
}
.e-min-height-px-150--mobile {
  @include mobile {
    min-height: 150px;
  }
}
.e-min-height-px-250--mobile {
  @include mobile {
    min-height: 250px;
  }
}
.e-max-height-px-150--mobile {
  @include mobile {
    max-height: 150px;
    width:auto;
  }
}
.e-max-height-100vh {
  max-height:100vh;
}
.e-max-height-img-80-vh {
  max-height: 80vh; 
}

.e-max-height-img-70-vh {
  max-height: 70vh; 
}

.e-max-height-img-60-vh {
  max-height: 60vh; 
}

.e-max-height-img-45-vh {
  max-height: 45vh; 
}

