.c-carousel {
  overflow:hidden;
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  .flickity-prev-next-button.next {
    @include mobile {
      right: -3px
    }
  }
  .flickity-prev-next-button.previous {
    @include mobile {
      left: -3px;
    }
  }
  .flickity-prev-next-button {
    top: 50%;
    width: 30px;
    height:30px;
    border-radius: 50%;
    transform: translateY(-50%);
      background-color:transparent;
      color:#FFF;
    @include mobile {
      width: 30px;
      height: 30px;
      display:block;
      border-radius:0
    }
  }
  .c-carousel__item {
    width:100%;
    &-container {
      max-width: 1600px;
      margin:0px auto;
      justify-content: center;
      flex-wrap:wrap;
      display:flex;
      padding:0px 25px;

      .item-text {
        color:#EFEFEF;
        padding-right:20px;
        width:30%;
        @include desktop {
          width:100%;
        }
        a {
          color:#EFEFEF;
        }
        h1 {
          font-size: 2.6em;
          @include tablet {
            font-size: 1.8em;
          }
          @include mobile {
            font-size: 1.8em;
          }
          @media (max-width: 320px){
            font-size: 1.5em;
            margin-bottom: .3em;
          }
        }
      }
      .item-image {
        width:70%;
        img {
          box-shadow: -6px 6px 5px rgba(#000,.5);
        }
        @include desktop {
          width:100%;
        }
      }
    }
  }
}