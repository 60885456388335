.c-event-ticket {
  background-color:#f6f6f6;
  font-size: .9rem;
  margin-bottom:1.5em;
 
  &--date-selected {
    border-bottom: 4px solid #111;
    padding: 10px 10px;
    a {
      &:focus {
        outline: none;
      }
    }
  }
  .c-event-ticket__date {

    &-day {
      width:110px;
      transform:translateY(-1em);
      background-color:$branding_white;
      color:#444;
      font-weight:bold;
      padding:20px;
      text-align:center;
      box-shadow:0px 0px 4px #444;
      border:2px solid transparent;
      &.is-active ,
      &:hover 
      {
        box-shadow:initial;
        border:2px solid red;
      }
      p {
        margin:0;
        padding:0;
        display:block;
        line-height: 1em;
        margin-bottom: .25em;
      }
      .item-day {
        font-size: .8rem;
        font-weight:600;
      }
      .item-number {
        font-size: 2rem;
        font-weight:bold;
      }
      .item-month {
        font-size: .8rem;
        font-weight:600;
      }
      .item-city {
        margin-top:16px;
        font-weight:600;
        min-height: 40px;
        font-size: 0.9em;
      }
    }
  }
  .item-content {
    ul {
      li {
        margin-bottom:.7em;
      }
    }
  }

  [data-button="clossed"] {

    .item-content ,
    .c-event-ticket__btn-plus--menos 
    {
      display:none;
    }
    &.is-active .item-content ,
    &.is-active .c-event-ticket__btn-plus--menos
    {
      display:block;
    }
    &.is-active .c-event-ticket__btn-plus--mas {
      display:none;
    }

  }
  .item-title {
    margin:10px 20px;
  }
  .c-event-ticket__title {
    background-color:darken($branding_blue,20);
    display:block;
    color:#FFF;
    margin-bottom:.5em;
    padding:8px 20px;
    h3 {
      margin:0;
      padding:0;
      font-weight:600;
    }
  }
  .c-event-ticket__btn {
    background-color:$branding_blue;
    color:#FFF;
    text-decoration:none;
    padding:8px 10px;
    box-shadow:2px 2px 2px #CCC;
    font-weight:600;
    font-size: .9rem;
    transition: .2s background-color;
    &:hover {
      background-color:darken($branding_blue,10);
    }
    @media (max-width: 1150px) {
      width:100%;
      display:block;
      text-align:center;
      padding:10px 8px;
    }
  }
  
  mark {
    margin-top:.5em;
    display:inline-block;
    padding:4px 10px;
    background-color:$branding_yellow;
    font-size: .9rem;
    font-weight:600;
  }
  .c-event-ticket__nav-flex {

    &--open {
      cursor:pointer;
      &:hover {
        .c-event-ticket__btn-plus {
          > label {
            border-bottom:1px solid;

          }
        }
      }
    }
    @media (max-width: 1150px) {
      justify-content: normal;
    }
  }
  .c-event-ticket__nav {
    &-date {
      ul {
        display:flex;
        flex-wrap:wrap;
      }

    }
    &-col {
      @media (max-width: 1150px) {
        width:100%;
        display:block;
        justify-content:normal;
      }
    }
    margin-top:.5em;
    > ul {
      margin:0;
      padding:0;
      > li {
        border-bottom:1px solid #CCC;
        padding-bottom:8px;
        padding:10px 10px;
        @media (max-width: 1150px) {
          padding:2px 8px;
        }
        p {
          // line-height: 40px;
          // padding:10px 10px;
          // margin:0;
          @media (max-width: 1150px) {
            // padding:2px 8px;
          }
        }
      }
    }
  }



  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      .popuptext {
        visibility: visible;
    -webkit-animation: fadeInPopup .3s;
    animation: fadeInPopup .3s;
      }
    }
  }

  .popup .popuptext {
    visibility: hidden;
    width: 180px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 0px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -88px;
  }

  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .popup .show {
    visibility: visible;
    -webkit-animation: fadeInPopup .3s;
    animation: fadeInPopup .3s;
  }

  @-webkit-keyframes fadeInPopup {
    from {opacity: 0;} 
    to {opacity: 1;}
  }

  @keyframes fadeInPopup {
    from {opacity: 0;}
    to {opacity:1 ;}
  }



}