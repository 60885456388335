#js-loader {
  position:fixed;
  width:100%;
  height:100%;
  background-color:rgba(#000,.8);
  top:0;
  left:0;
  z-index:9999;
  @extend .e-fade-out;

  &.is-remove {
    display:none;
    @extend .e-fade-out;
  }
  .loader {
  width: 10px;
  height: 10px;
  animation: loading 1s infinite ease-out;
  margin: auto;
  border-radius: 50%;
  top:50%;
  left:50%;
  position:fixed;
  transform:translate(-50%,-50%);
  background-color: #c100ff;

}
@keyframes loading {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(8);
    opacity: 0;
  }
}

  .sonar-wrapper {
    position: relative;
    z-index: 0;
    // overflow: hidden;
    padding: 8rem 0;
  }

  .sonar-emitter {
    position: relative;
    margin: 0 auto;
    width: 400px;
    height: 400px;
    border-radius: 9999px;
    background-image: linear-gradient(to top, $branding_red 0%, $branding_yellow 100%);
    display:none;
  }

  .sonar-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }
  .sonar-wave {
    animation: sonarWave 2s linear infinite;
  }

  @keyframes sonarWave {
    from {
      opacity: 0.4;
    }
    to {
      transform: scale(3);
      opacity: 0;
    }
  }

  .pinkBg {
    background-color: #ed184f;
    background-image: linear-gradient(-20deg, darken($branding_blue,10) 0%, $branding_blue 100%);
  }
  .intro-banner-vdo-play-btn {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin: -50px 0 0 -30px;
    border-radius: 100px;
    z-index: 1;
  }
  .intro-banner-vdo-play-btn i {
    line-height: 56px;
    font-size: 30px;
  }
  .intro-banner-vdo-play-btn .ripple {
    position: absolute;
    width: 160px;
    height: 160px;
    z-index: -1;
    left: 50%;
    top: 50%;
    opacity: 0;
    margin: -80px 0 0 -80px;
    border-radius: 100px;
    -webkit-animation: ripple 1.8s infinite;
    animation: ripple 1.8s infinite;
  }

  @-webkit-keyframes ripple {
    0% {
      opacity: 1;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes ripple {
    0% {
      opacity: 1;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .intro-banner-vdo-play-btn .ripple:nth-child(2) {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
  }
  .intro-banner-vdo-play-btn .ripple:nth-child(3) {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
  }

}