.c-artista {
  position:relative;
  overflow:hidden;
  max-width:1024px;
  margin:0 auto;

  .c-artista__eventos {
    background-color:#f6f6f6;
    padding:30px 20px;
    margin:30px 0;
    border-bottom:4px solid #111;

  }

  .c-artista__generos {
    &__title {
      &::after {
        content:(":")
      }
    }
  }

  .c-artista__eventos-title {
    font-weight:500;
    font-size: 2em;
    margin-top:0;
    color:$branding_red;
    display:inline-block;
  }
  .c-artista__photo {
    display:block;
    height:500px;
    width:100%;
    background-position: center center;
    background-size:cover;
    @include mobile {
      height:350px;
    }
  }
  .c-artista__title {
    position:absolute;
    bottom:30px;
    left:20px;
    h1 {
      color:#FFF;
      text-shadow: rgba(0, 0, 0, 0.6) 0px 2px 4px;
      font-size: 1.8em;
    }
  }

  .c-artista__eventos-information {
    &-content {
      font-size: .9rem;
    }
    &-title {
      margin:0;
      font-weight:600;
      margin-bottom: 1.5em;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
    &-text {
      overflow:hidden;
      p {
        line-height: 1.8em;
        padding:0;
        margin:0;
        margin-bottom:.5em;
      }
    }
    &-photo {
      img {
        width:400px;
        max-width:100%;
        margin:0 30px 10px 0;
        float:left;
        @include mobile {
          float:none;
          margin-bottom: 30px;
        }
      }
    }
  }

  .c-artista__evento-content {
    margin:1em;
    border-left:1px solid #CCC;
    padding-left:30px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    flex:1;

    .item-btn {
      @extend .e-btn, .e-btn--red , .e-btn--radius;
    }

    @include mobile {
      margin:1em 0;
      padding-left: 0;
      border-left:0;
      display:block;
      text-align:center;
      > div {
        width:100%;
      }
    }
    header {
      h2 {
        font-weight:600;
        margin:0;
        padding:0;
        padding-top:10px;
        padding-bottom:0px;
        font-size: 1.1em;
      }
      p {
        margin:.5em 0;
      }
    }
  }



  
  .c-artista__eventos-nav {
    @include reset_list;
    ul {
      li {
        transition:.2s background-color;
        a {

          @extend .link;
          display:flex;
          align-items: center;
          flex-wrap: wrap;
          border-top:1px solid #CCC;
          padding:20px 0px;


        }
        &:hover {
          background-color:#FFF;
        }
      }
    }
  }
  .c-artista__eventos-date {
    width:110px;
    font-weight:bold;
    text-align:center;
    @include mobile-xs {
      width:100%;
    }
    p {
      margin:0;
      padding:0;
      display:block;
      line-height: 1em;
      margin-bottom: .25em;
    }
    .item-day {
      font-size: .8rem;
      font-weight:600;
    }
    .item-number {
      font-size: 2rem;
      font-weight:bold;
    }
    .item-month {
      font-size: .8rem;
      font-weight:600;
    }
    .item-city {
      font-size: .9rem;
      font-weight:500;
      color:$branding_red;
    }
    .item-hour {
      font-size: .8rem;
      font-weight:500;
      color:$branding_red;
    }
  }


}