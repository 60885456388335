// e-btn
.e-btn {
  background-color:$branding_white;
  text-decoration:none;
  font-weight:bold;
  padding:10px 22px;
  border-radius:0px;
  position:relative;
  border:0;
  cursor:pointer;
  box-sizing:border-box;
  color:#000;
  transition:.2s;
  display:inline-block;
  &::after {
    // content:(">");
    // margin-left:.5em;
    // transition:.2s;
  }
  &:hover {
    background-color:$branding_black;
    color:$branding_white;
    box-shadow:0px 0px 5px #666;
    &::after {
      margin-left:1em;
    }
  }
  &--no-arrow {
    &::after ,
    &::after 
    {
      display:none;
    }
  }
  &--transparent {
    background-color:transparent;
    color:#3897f0 !important;
    font-weight: 500;
    border:1px solid #666;
    box-sizing:border-box;
    display:inline-block;
    border-radius:3px;
    border-width:3px;
    border-style:solid;
    border-color:#3897f0;
    font-weight:bold;
    &:hover {
      background-color:#3897f0;
      color:#FFF !important;
    }
  }
  &--brown {
    background-color:$branding_brown;
    color:#FFF;
    font-weight: 500;
    border:0;
    box-sizing:border-box;
    display:inline-block;
  }
  &--red {
    background-color:$branding_red;
    color:#FFF;
    font-weight: 500;
    border:0;
    box-sizing:border-box;
    display:inline-block;
  }
  &--pink {
    background-color:#c5004e;
    color:#FFF;
    border:1px solid #000;
    border-radius:30px;
    border-bottom:3px solid #333;
    padding-right:40px;
    padding-left:40px;
    // box-shadow:0px 2px 8px #888;
  }
  &--radius {
    border-radius:20px;
  }
  &--big-padding {
    padding-right:40px;
    padding-left:40px;
  }
}