.c-instagram {
  &__link {
    figure {
      img {
        transition:.2s all;
      }
      figcaption {
        i {
        }
      }
    }
    &:hover {
      figure {
        img {
          opacity: .5;
        }
        figcaption {
          i {
            transform:scale(1.2);
          }
        }

      }
    }
  }
}