.c-tags {
  @include reset_list;

  ul {
    li {
      display:inline-block;
      a {
        text-decoration:none;
        color: #444;
        display: inline-block;
        border: 0;
        background: #f0f0f0;
        padding: 3px 15px 3px 25px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        font-size: 12px;
        line-height: 2.3;
        transition:.2s background-color;
        &:after {
          transition:.2s border-left;
        }
        &:hover {
          background: darken(#f0f0f0,10);
          &:after {
            border-left-color:darken(#f0f0f0,10);
          }
        }
        &:before {
          background: #fff;
          border-radius: 10px;
          content: '';
          height: 6px;
          left: 10px;
          position: absolute;
          width: 6px;
          top: 14px;
        }
        &:after {
          background: #fff;
          border-bottom: 17px solid transparent;
          border-left: 10px solid #f0f0f0;
          border-top: 17px solid transparent;
          content: '';
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}