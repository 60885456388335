.c-tickets {
  @include reset_list;
  margin-top: 50px;
  padding-bottom: 150px;
  a {
    text-decoration:none
  }

  .c-tickets__header {
    h1 {
      text-align:center;
      @include font-size(4.5rem);
      padding:0;
      margin:0;
    }
  }

  .c-tickets__nav {
    margin-top:.5em;
    ul {
      text-align:center;
      li {
        display:inline-block;
        a {
          padding:15px 30px;
          margin:7px 6px;
          display:block;
          font-weight:500;
          color:$branding_blue;
          transition:.2s background-color;
          @include mobile {
            font-size: .9em;
            padding:10px 24px;
          }
          &:hover {
            color:#FFF;
            background-color:$branding_blue;
          }
          &.is-active {
            color:#FFF;
            background-color:$branding_yellow;
          }
        }
      }
    }
  }

  .c-tickets__grid {
    margin-top:1em;
    display:grid;
    grid-template-columns:repeat(4,1fr);
    grid-gap:3%;
    @include desktop {
      grid-template-columns:repeat(3,1fr);
    }
    @include tablet {
      grid-template-columns:repeat(2,1fr);
    }
    @include mobile-xs {
      grid-template-columns:repeat(1,1fr);
      grid-gap:30px;
    }
  }

  .c-tickets__article-date {
    text-align:center;
    background-color:$branding_blue;
    color:#FFF;
    padding:1px 20px;
    width:96%;
    margin:0 2%;
    font-weight:bold;
    @include font-size(1.4rem);
    &--before {
      background-color:$branding_yellow;
    }
  }
  article {
    transition:.4s all;
    margin-bottom: 1.5em;
    a {
      header {
        h1 {
          text-align:center;
          margin:.7em 0;
          padding:0;
          @include font-size(1.7rem);
        }
        figure {
          position:relative;
          overflow:hidden;
          img {
            transition:.8s all;
            display:block;
            width:100%;
            height:230px;
            object-fit: cover;
          }
          i {
            transition:.4s all;
            opacity:0;
            position:absolute;
            top:50%;
            left:50%;
            font-size: 30px;
            color:#FFF;
            text-align:center;
            vertical-align: middle;
            transform:translate(-50% ,-50%);
            background-color:$branding_blue;
            border-radius:50%;
            width:70px;
            height:70px;
            line-height: 70px;
          }
        }
      }
      &:hover {
        figure {
          img {
            opacity:.5;
            transform:scale(1.1);
                      }
          i {
            opacity:1;
          }
          &::before {
            content:("");
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color:#111;
          }
        }
      }
    }
    footer {
      text-align:center;
      font-size: .9em;
    }
    box-shadow: 0px 8px 8px rgba(#eee,1);
    &:hover
    { 
      box-shadow: 0px 8px 8px rgba(#000,.5);
      background-color:#fcfcfc;
      transform:translateY(-5px);
    }
  }
}