// General border box, que no suporta normalize.css
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

// https://css-tricks.com/box-sizing/ 
// Millors practiques per als 'pseudo elements'
*, *:before, *:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}


body {
	font-family: $Poppins;
	// font-weight: 300;
	font-size:16px;
	color:$branding_black;
	@include mobile {
		font-size:15px;
	}

}
h1 {

}
h2 {

}
h1 , h2 , h3 , h4 , h5 , h6 {
	font-weight: $font-bold-theme;

	
}

a[href^="tel"] {
  color: inherit; /* Inherit text color of parent element. */
  text-decoration: none; /* Remove underline. */
  /* Additional css `propery: value;` pairs here */
}


details[open] summary ~ * {
  animation: details-sweep .5s ease-in-out;
}
@keyframes details-sweep {
  0%    {opacity: 0; transform:translateY(5px);}
  100%  {opacity: 1; }
}


b , strong {
	font-weight: $font-bold-theme;
} 

p {
	line-height: 1.5em;
}

ol > li > ul {
	
	margin-top: .5em;
	display: block;
}

a {
	color:$branding_black;
}

// no suporta normalize.css, queda el defecte pel browser
figure {
	margin:0;
	padding:0;
}
iframe {
  max-width:100%;
}
img 
{
	max-width: 100%;
	height:auto;
} 