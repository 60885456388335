.c-video {
  @keyframes e-fade-in-video {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  background: url($path + 'assets/img/back-video.jpg') center;
  background-size: cover;
  min-height:490px;
  @include mobile-xs {
    min-height:350px;
  }
  // @media (min-width: $tablet_px) {
    iframe {
      max-width:initial;
      animation: .4s e-fade-in-video 1s cubic-bezier(0, 0.575, 0.565, 1.000) both;
    }
  // }
  .c-video__header {
    color:#FFF;
    text-align:center;
    position:relative;
    margin-top: 100px;
    &::before {
      content:("");
      display:block;
      position:absolute;
      width:1px;
      height:80px;
      background-color:#FFF;
      top:-100px;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
    }
    p {
      padding:0;
      font-weight: bold;
      text-transform: uppercase;
      margin:0 0 1em 0;
    }
    h1 {
      margin:0;
      padding:0;
      @include font-size(4.5rem);
    }
  }



  .body--page-index & {
    min-height:80vh;
    @include tablet {
      min-height:98vh;
    }
  }
  z-index:-1;
  @include tablet {
    iframe {
      opacity: 0;
    }
  }
  .elementor-shape .elementor-shape-fill {
    fill: #fff;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  svg.triangle {
    height: 65px;
    transform: translateX(-50%) rotateY(180deg);
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    @media (max-width: 320px){
      display:none;
    }
  }
  .elementor-shape[data-negative=false].elementor-shape-bottom, 
  .elementor-shape[data-negative=true].elementor-shape-top 
  {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .elementor-shape-bottom {
    bottom: -1px;
  }
  .elementor-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    z-index: 999;
  }
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    .hi 
    {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      color: #fff;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      span {
        cursor: pointer;
        text-decoration: underline;
      }
      i {
        font-style: normal;
      }
    }
    .c-video__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media (max-width: 320px){
        position: initial;
        padding-top:60px;
        transform: initial;
      }
    }
  }
  .tv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    .screen {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: auto;
    }
  }
  @media (max-height: 30rem) {
    display:none !important;
  }
}